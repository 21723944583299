<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">
        <el-form :inline="true" :model="queryForm" style="">
          <el-form-item label="名称" min-width="120">
            <el-input size="small" v-model="queryForm.keyword" placeholder="请输入关键字"></el-input>
          </el-form-item>


          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>


            <!--                        <el-upload style="display: inline-block; margin-left: 10px;" action="" :multiple="true"
                                        :http-request="uploadOss" :on-success="handleFileUpload" :on-progress="handleProcess"
                                        :show-file-list="false" name="image">
                                        <el-button size="small" type="primary" icon="el-icon-upload">上传</el-button>
                                    </el-upload>-->
            <el-button size="small" type="primary" icon="el-icon-upload" @click="handleAdd">上传文件</el-button>

            <el-button size="small" style="margin-left: 10px;" type="primary" icon="el-icon-folder"
                       @click="addFolder">文件夹
            </el-button>


          </el-form-item>
        </el-form>

      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>文件管理</el-breadcrumb-item>
          <el-breadcrumb-item>文件列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <div style="height:4px">
        <div id="processbox" style="height:4px;border-radius: 2px; background-color: green; width: 0px;"></div>
      </div>
      <el-card>


        <div style="display:flex">
          <div class="nextpath" @click="goPath(0)">根目录</div>
          <div class="nextpath" @click="goPath(p.id)" v-for="(p,i) in pathList" :key="i"><i
            class="el-icon-arrow-right"></i>{{ p.filename }}
          </div>
        </div>


        <el-table v-tableFit stripe :data="DataList"
                  style="width: 100%;margin-bottom: 20px; margin-top: 5px; color: #000;"
                  border size="mini">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="cover" label="类型" width="60">
            <template slot-scope="scope">
              <div style="text-align:center">

                <img v-if="scope.row.ftype=='file'" :src="'./img/icon/'+scope.row.filetype+'.png?'"
                     class="typeicon"/>
                <img v-if="scope.row.ftype=='folder'" :src="'./img/icon/folder.png'" class="typeicon"/>


              </div>

            </template>
          </el-table-column>
          <el-table-column prop="filename" label="文件名称" min-width="230">
            <template slot-scope="scope">
              <div class="filenameitem">
                <span @click.stop="viewInfo(scope.row)"> {{ scope.row.filename }}</span>
                <div class="fileicon">
                  <i class="el-icon-download" title="下载" @click.stop="openFile(scope.row)"></i>
                  <i v-if="teacherid==scope.row.ctch_uid" class="el-icon-delete" title="删除"
                     @click.stop="deleteFile(scope.row)" style="margin-left:10px"></i>
                  <i v-if="teacherid==scope.row.ctch_uid" class="el-icon-edit" title="重命名"
                     @click.stop="renameFile(scope.row)" style="margin-left:10px"></i>
                </div>
              </div>

            </template>
          </el-table-column>

          <el-table-column prop="id" label="上传日期" width="180">
            <template slot-scope="scope">
              {{ scope.row.cdate }}

            </template>
          </el-table-column>

          <el-table-column prop="filename" label="文件大小" width="120">
            <template slot-scope="scope">
              {{ getSize(scope.row.filesize) }}

            </template>
          </el-table-column>
          <el-table-column prop="filename" label="上传用户" width="120">
            <template slot-scope="scope">
              <img :src="scope.row.tch_avatar||scope.row.stu_avatar" class="headimg"/>
              <span
                style="vertical-align:middle;line-height: 32px; margin-left: 5px; color: #888;">{{ scope.row.username || scope.row.stu_name }}</span>
            </template>
          </el-table-column>
        </el-table>


      </el-card>
    </div>


    <el-dialog :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
               :destroy-on-close="true" @close="dialogshow = false" width="800px" custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">上传文件</span>
      </div>
      <div>

        <el-form :model="formData" ref="EditItem" label-width="120px">
          <el-form-item label="发送对象">

            <el-button size="small" v-if="!sendToAll" style="margin-right: 20px;"
                       @click="dialogVisible = true">
              {{ choosedTeachers > 0 ? '教师' + choosedTeachers + '人' : '' }}
              {{ choosedClasses > 0 ? '班级' + choosedClasses + '个' : '' }}
              {{ choosedClasses == 0 && choosedTeachers == 0 ? '手动选择' : '' }}
            </el-button>

            <el-checkbox v-model="sendToAll">全部人员</el-checkbox>

            <el-checkbox v-model="allTeacher">对所有内部教职工</el-checkbox>

            <el-checkbox v-model="allStudent">对所有学生</el-checkbox>

          </el-form-item>
          <el-form-item label="选择文件">
            <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss"
                       :on-progress="handleProcess"
                       :on-success="uploadFiles"
                       :show-file-list="false" multiple>
              <el-button type="primary" size="small">点击上传</el-button>
            </el-upload>
            <ul v-if="formData.files && formData.files.length" class="el-upload-list el-upload-list--text" style="width: 320px;">
              <li class="el-upload-list__item is-success" v-for="(file,index) in formData.files" :key="index">
                <a class="el-upload-list__item-name"><i class="el-icon-document"></i>{{file.fileName}}</a>
                <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-circle-check"></i></label>
                <i class="el-icon-close" @click="handleRemoveFile(index)"></i></li></ul>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogshow = false">取消</el-button>
        <el-button type="primary" @click="saveForm">保存</el-button>
      </div>
    </el-dialog>

    <deptuser v-if="dialogVisible" :oteachers="formData.target_teachers" :oclasses="formData.target_classes"></deptuser>
  </div>
</template>

<script>

import deptuser from "@/views/com/deptuser/deptuser.vue";

export default {
  components: {deptuser},
  data() {
    return {
      queryForm: {
        keyword: ""
      },
      pid: 0,
      teacherid: 0,
      DataList: [],
      pathList: [],
      dialogshow: false,
      formData: {
        target_teachers: "", //部门教师
        target_classes: "", //班级
        files: [],
      },
      allStudent: false,
      allTeacher: false,
      sendToAll: false, //发送给所有用户
      dialogVisible: false,
      choosedTeachers: 0,
      choosedClasses: 0,
    }
  },
  mounted() {
    this.$http.post("/api/get_teacher_info").then(res => {
      this.teacherid = res.data.id
      this.getList()
    })

  },
  watch: {
    $route: function (n, o) {
      console.log(n)
      let dir = n.query.dir
      if (dir) {
        dir = dir.split(',')

        let pid = dir[dir.length - 1];
        let isback = false;
        for (let i = 0; i < this.pathList.length; i++) {
          if (this.pathList[i].id == pid && i != this.pathList.length - 1) {
            console.log(i, pid, this.pathList.length)
            this.pathList.splice(i, 1)
            isback = true
            break
          }
        }
        console.log(isback)
        if (isback) {
          this.pid = pid
          this.getList()
        }


      } else {
        this.pathList = []
        this.pid = 0
        this.getList()
      }


    }
  },
  methods: {
    getSize(size) {
      if (size) {


        if (size < 1024) {
          return size + "B"
        } else if (size >= 1024 && size < 1024 * 1024) {
          return parseInt(size / (1024) * 10) / 10 + "KB"
        } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
          return parseInt(size / (1024 * 1024) * 10) / 10 + "MB"
        } else if (size >= 1024 * 1024 * 1024 && size < 1024 * 1024 * 1024 * 1024) {
          return parseInt(size / (1024 * 1024 * 1024) * 10) / 10 + "GB"
        }
      } else {
        return "-"
      }
    },
    getList() {
      this.$http.post("/api/t_sch_filesstore_list", {
        pid: this.pid,
        ktype: "filename",
        keyword: this.queryForm.keyword
      }).then(res => {
        this.DataList = res.data
      })
    },
    addFolder() {
      this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        if (value.trim()) {
          this.$http.post("/api/t_sch_filesstore_edit",
            {
              pid: this.pid,
              ftype: "folder",
              filename: value.trim(),
              filesize: 0,
              filetype: '',
              url: '',
              is_share: 1
            }).then(res => {
            this.$message.success("上传成功")
            this.getList()
          })
        }
      }).catch(() => {

      });

    },
    search() {
      this.getList()
    },
    handleAdd() {
      this.dialogshow = true
      this.formData.files = []
      this.formData.target_teachers = ""
      this.formData.target_classes = ""
      this.choosedClasses = 0
      this.choosedTeachers = 0
      this.allStudent = false
      this.allTeacher = false
      this.sendToAll = false
    },
    handleProcess(e) {

      let p = e.loaded / e.total * 100
      $("#processbox").show().css("width", p + "%")
    },
    handleFileUpload(e) {

      let filename = e.fileName
      let filetype = e.fileName.substr(e.fileName.lastIndexOf(".") + 1)
      console.log(filename, filetype)
      $("#processbox").hide()
      this.$http.post("/api/t_sch_filesstore_edit",
        {
          pid: this.pid,
          ftype: "file",
          filetype: filetype,
          filename: filename,
          filesize: e.size,
          url: e.src,
          is_share: 1
        }).then(res => {
        this.$message.success("上传成功")
        this.getList()
      })
    },
    viewInfo(e) {

      if (e.ftype == "folder") {
        this.pid = e.id;
        this.pathList.push(e)
        this.getList()
        window.location.href = "/#" + "/files?dir=" + encodeURIComponent(this.pathList.map(a => {
          return a.id
        }).join(","))
        // this.$router.push("/files?dir="+encodeURIComponent(this.pathList.map(a=>{return a.id}).join(",")))
      } else {
        this.openFile(e)
      }
    },
    goPath(id) {
      if (id == 0) {
        this.pathList = [];

      } else {
        for (let i = 0; i < this.pathList.length; i++) {
          if (this.pathList[i].id == id) {
            this.pathList.splice(i + 1)

            break
          }
        }
      }
      this.pid = id
      this.getList()
    },
    openFile(e) {
      if (e.url) {
        //window.open(e.url, "_blank")
        this.$http.get(e.url, {
          responseType: 'arraybuffer'
        }).then(r => {
          var blob = new Blob([r.data], { type: 'application/octet-stream;charset=UTF-8' })
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.style.display = 'none'
          downloadElement.href = href
          downloadElement.download = decodeURIComponent(e.filename) // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href)
        })
      }
    },
    deleteFile(e) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/t_sch_filesstore_delete", {
          id: e.id
        }).then(res => {
          if (res.data.affectedRows) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败，没有权限!'
            });
          }

        })

      }).catch(() => {

      });
    },
    renameFile(e) {
      this.$prompt('请输入新的文件名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        if (value.trim()) {
          e.filename = value.trim()
          this.$http.post("/api/t_sch_filesstore_edit", e).then(res => {
            this.$message.success("修改成功")
            this.getList()
          })
        }
      }).catch(() => {

      });
    },
    uploadFiles(e) {
      $("#processbox").hide()
      e.filetype = e.fileName.substr(e.fileName.lastIndexOf(".") + 1)
      e.is_share = 1
      this.formData.files.push(e)
    },
    handleRemoveFile(idx) {
      this.formData.files.splice(idx, 1)
    },
    setTarget(e) {
      if (e.teachers && e.teachers.length > 0) {
        this.choosedTeachers = e.teachers.length
        this.formData.target_teachers = "0," + e.teachers.join(',') + ",0"
      } else {
        this.formData.target_teachers = "0"
        this.choosedTeachers = 0
      }
      if (e.classes && e.classes.length > 0) {
        this.formData.target_classes = "0," + e.classes.join(',') + ",0"
        this.choosedClasses = e.classes.length
      } else {
        this.formData.target_classes = "0"
        this.choosedClasses = 0
      }
    },
    saveForm() {
      if (this.sendToAll) {
        this.formData.target_teachers = "all";
        this.formData.target_classes = "all";
      } else {
        //发送对象
        if (this.allStudent) {
          this.formData.target_classes = "all"
        }

        if (this.allTeacher) {
          this.formData.target_teachers = "all"
        }
      }
      let item = JSON.parse(JSON.stringify(this.formData));
      if (!item.files.length) {
        return this.$message.error('请上传文件')
      }
      let saveFile = idx => {
        if (idx < item.files.length) {
          let file = item.files[idx]
          let formData = {
            pid: this.pid,
            ftype: "file",
            filetype: file.filetype,
            filename: file.fileName,
            filesize: file.size,
            url: file.src,
            is_share: 1,
            target_classes: item.target_classes,
            target_teachers: item.target_teachers,
          }
          this.$http.post('/api/t_sch_filesstore_edit', formData).then(res => {
            saveFile(idx + 1)
          });
        } else {
          this.dialogshow = false
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.getList()
        }
      }
      saveFile(0)
    },
  },

};
</script>

<style scoped>
.filerow {
  padding: 10px;
  height: 30px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  line-height: 30px;
  display: flex;
  color: #333;
}

.filerow:hover {
  background-color: aliceblue;
}


.filename {
  width: 30%;
  padding-left: 10px;
}

.filedate {
  width: 120px;
  font-size: 12px;
}

.headimg {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
}

.typeicon {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  vertical-align: middle;
}

.filenameitem {
  position: relative;
}

.fileicon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  color: #409EFF;
  display: block;
}

.filenameitem:hover .fileicon {
  display: block;
}

.filenameitem:hover {
  color: #409EFF;
  cursor: pointer;
}

.nextpath {
  font-size: 14px;
  color: #409EFF;
  cursor: pointer;
}
</style>